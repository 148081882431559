<template>
    <v-main>
        <section id="step-tilte" class="pt-16">
            <v-container class="step-container">
                <h1 class="text-center step-h1 mb-10">Шаг 1: "Очищение"</h1>
                <v-row class="justify-center align-center">
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <v-img class="step-main-img mx-auto" src="/img/converter/1month.png"/>
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                    >
                        <p class="step-p-main">Продолжительность 1 месяц.</p>
                        <p class="step-p-main">Главная задача - очистить и подготовить организм к восстановлению и питанию.</p>
                        <p class="step-p-main">
                            Можно принимать самые дорогие и эффективные биоактивные добавки и витамины, но если ЖКТ,
                            межклеточная жидкость, лимфа, сосуды и капилляры загрязнены, их усвояемость будет минимальной.
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <h2 class="text-center step-h2 pb-10">Состав набора</h2>

        <section id="sostav">
            <v-container class="step-container">
                <h3 class="step-h3 mb-5">Питьевой гель Алоэ Вера Персик с инулином</h3>
                <v-row class="justify-center">
                    <v-col
                        cols="12"
                        md="4"
                        class="text-center"
                    >
                        <v-img class="step-sostav-img mx-auto" src="/img/converter/AVpeach.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/made-in-germany.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/IASC.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/fresenius.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/kolner-list.png"/>
                    </v-col>

                    <v-col
                        cols="12"
                        md="8"
                    >
                        <p class="step-p">
                            Универсальный продукт. Очищает, восстанавливает и питает организм одновременно.
                            Поэтому включен в состав всех трех программ.
                        </p>
                        <p class="step-p">
                            В составе Алоэ Вера не менее 150 биологически активных веществ, определяющих его уникальность и целебные свойства.
                        </p>
                        <p class="step-p">
                            Проникая вглубь клеток в 4 раза быстрее воды, Алоэ Вера помогает выводить токсины и доставлять ценные вещества.
                        </p>

                        <h4 class="step-h4 mb-3">Действие продукта на организм:</h4>
                        <ul class="step-ul">
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Бережно очищает организм
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Питает важнейшими микро и макро элементами
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Нормализует работу желудочно-кишечного тракта
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Повышает иммунитет
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Нормализует уровень сахара
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Стабилизирует уровень холестерина
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Улучшает сон
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Не содержит ГМО, сахар, глютен, лактозу, а также вещества животного происхождения
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                На 300% увеличивает биодоступность (усвояемость) поступающих с ним витаминов *
                            </li>
                        </ul>

                        <p class="snoska">
                            <span>*</span>
                            Исследование проводились в Charité – Universitätsmedizin Berlin. Контрольная группа принимала только витамин С и Е. Экспериментальная группа принимала витамины С и Е вместе с Алоэ Вера гелем дважды в день по 30 мл. Исследование показало что прием Алоэ Вера геля увеличил уровень витамина С на 304%, витамина Е на 369%.
                        </p>

                        <h2 class="soctav-title-green">В составе геля Алоэ Вера Персик:</h2>
                        <v-expansion-panels popout focusable>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Витамины</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Гели Алое Вера богаты витаминами:</p>
                                    <ul class="step-ul-exp">
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            А – для улучшения зрения, защиты клеток от вредных химических элементов, благоприятное воздействие на кожу
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            В1 – «витамин оптимизма», участвует в работе нервной системы, является профилактикой старения для клеток мозга,
                                            оптимизирует познавательную активность и память, участвует в углеводном обмене, способствует снижению риска инфарктов и инсультов и т.д.
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            В2 – способствует сопротивляемости стрессам, участвует в трансформации жиров и углеводов в энергию, влияет на иммунные процессы, состояние кожи
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            В3 –для регуляции обмена веществ
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            В4 – витамин для мозга, нервной системы, умственной деятельности, участвует в жировом и холестериновом обмене, защищает печень
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            В6 – важен для метаболизма и кроветворения
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            В9 – стимулирует процессы восстановления во всех органах и тканях, необходим для кроветворения, процессов роста
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            В12 – важен для пищеварения, обмена веществ, образования эритроцитов
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            С – антиоксидант, способствует подавлению инфекции, регенерации клеток кожи
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            Е – антиоксидант, предохраняет мембраны клеток от окисления, света и радиации
                                        </li>
                                    </ul>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Минералы</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Содержит свыше 30 типов минералов, необходимых для нормального водносолевого обмена.</p>
                                    <ul class="step-ul-exp">
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            кальций и фосфор (формирование и функционирование костных тканей)
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            калий (нервная система, память)
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            железо (образование гемоглобина, фиксация кислорода клетками крови)
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            натрий (для поддержания рН и водносолевого обмена)
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            хлор (функции мозга и мышц, дезинфицирующее, антисептическое свойства)
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            магний и марганец (для нормального функционирования мышц и нервной системы)
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            хром (обмен жиров)
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            медь (для активности антиокислительной системы организма)
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            цинк (синтез белков при заживлении ран)
                                        </li>
                                    </ul>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Инулин</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Пребиотик (вещество, образующее благоприятную среду для роста полезной микрофлоры). В организме образует гелеобразную субстанцию, которая обволакивает слизистые желудочно-кишечного тракта, защищая от неблагоприятного воздействия (алкоголя, вредных веществ). В кишечнике инулин становится питательной средой для бифидо и лактобактерий, которые очищают и защищают организм. Помогает в очищении, работе кишечника, обмене веществ, усвоении пищи. Инулин не вызывает повышения уровня сахара в крови, наоборот, способствует длительному сохранению чувства сытости. Под действием инулина активизируются иммунные клетки.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Глюкоманнан</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Науке давно известны его очищающие свойства, в народе его называют «метлой для кишечника». В желудке низкокалорийный глюкоманнан впитывает воду, как губка, создавая чувство насыщения и препятствуя усвоению углеводов и жиров.</p>
                                    <p class="step-p-exp">Глюкоманнан поглощает холестерин, желчные кислоты, тяжелые металлы: он предотвращает их всасывание и выводит из организма.</p>
                                    <p class="step-p-exp">Способствует расщеплению жиров на внутренних органах подкожно жировой клетчатки, усиливая энергетический обмен.</p>
                                    <p class="step-p-exp">Выполнив свою работу, глюкоманнановая губка покидает организм естественным образом, способствуя устранению запоров. Очищающее действие глюкоманнана эффективно снижает уровень холестерина, помогает контролировать уровень сахара в крови и снижать вес.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Аминокислоты</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Строительные блоки белков и энзимов, регулирующих метаболизм и функционирование органов и тканей. Организму человека для поддержания здоровья нужны 22 аминокислоты, из которых 8 организм может синтезировать сам, а другие могут поступать в составе Алоэ Вера.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Лигнин</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Обладает свойством соединяться с желчными кислотами в кишечнике, благодаря чему связанная желчь вместе с избытком холестерина удаляется из организма.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Энзимы</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Нормализуют процесс пищеварения, расщепляя пищу.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Сахароза</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Алоэ Вера содержит моносахариды: глюкоза и фруктоза, полисахариды, которые необходимы для регуляции высокого кровяного давления и пищеварения, препятствуют возникновению артрита, снижают содержание холестерола, улучшают функционирование печени, стимулируют клетки костной ткани за счет усвоения фосфора и кальция.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Ацеманнан</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Обладает антивирусным и иммуномодулирующим действием. Помогает клеткам быть более устойчивыми к вирусам и патогенным бактериям. Улучшает общий клеточный метаболизм и функционирование.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Антрахиноны и их производные</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Являются болеутоляющими агентами, обладают антибактериальными, противогрибковыми и антивирусными свойствами.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section id="sostav">
            <v-container class="step-container">
                <h3 class="step-h3 mb-5">Комплекс минералов и микроэлементов ПроБаланс</h3>
                <v-row class="justify-center">
                    <v-col
                        cols="12"
                        md="4"
                        class="text-center"
                    >
                        <v-img class="step-sostav-img mx-auto" src="/img/converter/Probalance.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/made-in-germany.png"/>
                    </v-col>

                    <v-col
                        cols="12"
                        md="8"
                    >
                        <p class="step-p">
                            Универсальный продукт. Очищает, восстанавливает и питает организм одновременно.
                            Поэтому включен в состав всех трех программ.
                        </p>
                        <p class="step-p">
                            Сбалансированный комплекс минералов и микроэлементов а так же ценная комбинация цитратов и карбонатов делают этот продукт супер эффективным.
                        </p>

                        <h4 class="step-h4 mb-3">Действие продукта на организм:</h4>
                        <ul class="step-ul">
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Выводит из организма соли тяжелых металлов, токсины и радионуклиды
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Нормализует пищеварение, создает неблагоприятные условия для гнилостных бактерий и паразитов
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Восполняет недостаток минералов в организме
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Поддерживает кислотно-щелочной баланс в организме
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Защищает от оксидантного стресса
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Способствует расслаблению и нормализации ночного сна
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Способствует снижению хронической усталости
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Нормализует мужскую половую функцию
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Рекомендован спортсменам для нейтрализации молочной кислоты в мышцах
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Нормализует функционирование нервной и мышечной систем
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Компоненты продукта способствуют улучшению общего хорошего самочувствия и коррекции веса
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Не содержит ГМО, сахар, глютен, лактозу, а также вещества животного происхождения
                            </li>
                        </ul>

                        <h2 class="soctav-title-green">В составе комплекса Про Баланс:</h2>
                        <v-expansion-panels popout focusable>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Кальций</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">
                                        Макроэлемент. Нехватка кальция приводит к ухудшению состояния костей, волос, зубов, развитию остеопороза, возникновению почечной и сердечной недостаточности,
                                        неправильной работе щитовидной железы, замедлению передачи нервных импульсов.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Калий</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">
                                        Нормализует баланс клеточной и межклеточной жидкости, кислотно-щелочной и водносолевой баланс, поддерживает уровень кровяного и осмотического давления,
                                        а также нервно-мышечную возбудимость. Обеспечивает проводимость, межклеточные контакты и биоэлектрическую активность клеток. Участвует в нервной регуляции
                                        сердечных сокращений. Выполняет роль катализатора при углеводном и белковом обмене. Влияет на работу почек.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Магний</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">
                                        Участвует в выработке энергии, усвоении глюкозы, передаче нервного сигнала, синтезе белков, построении костной ткани,
                                        регуляции расслабления и напряжения сосудов и мышц. Оказывает успокаивающее действие, снижая возбудимость нервной системы.
                                        Выступает как противоаллергический и противовоспалительный фактор – защищает организм от инфекции, участвуя в выработке антител.
                                        Играет значительную роль в процессах свертываемости крови, регуляции работы кишечника и других органов. Магний влияет на работоспособность,
                                        утомляемость, стрессоустойчивость, работу сердца и нервов, кровяное давление, кислотность желудочного сока.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Медь</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">
                                        Медь нужна мозгу, крови, мышцам, костям, почкам и печени. При дефиците меди функционирование всех этих органов может нарушиться.
                                        В присутствии меди синтез белков и различных ферментов происходит более активно, быстрее растут и формируются ткани и клетки.
                                        Медь необходима для лучшего функционирования иммунной системы и процесса кроветворения.
                                    </p>
                                    <p class="step-p-exp">
                                        Выполняет в организме транспортную функцию, доставляя к клеткам организма нужные вещества, благодаря чему нормализуется обмен веществ.
                                        Обеспечивает здоровье и прочность костей, предотвращает вымывание минералов и служит профилактикой остеопороза.
                                    </p>
                                    <p class="step-p-exp">
                                        При участии меди образуется соединительная ткань, из которой состоит внутренний слой сосудов – эластин. В рамках пищеварительного процесса
                                        медь помогает вырабатывать нужные соки и ферменты, защищая всю систему от воспалительных процессов и повреждений и помогая бороться со свободными радикалами.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Молибден</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">
                                        Входит в состав ряда ферментов, выполняющих важные физиологические функции, помогает утилизации железа и устраняет последствия отравления алкоголем и сульфитами.
                                        Будучи антиоксидантом, молибден играет важную роль в нормализации мужской половой функции, обмена веществ и оказывает стимулирующее действие на ферменты,
                                        необходимые для роста и развития клеток. Благодаря тому, что молибден удерживает фтор, он предотвращает появление кариеса и поддерживает зубы в хорошем состоянии.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Натрий</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">
                                        Активно участвует в функционировании процессов обмена внутри клеток и между ними, нормализует осмотическое давление, отвечает за возбудимость
                                        нервно-мышечных волокон и их правильное функционирование. Является проводником глюкозы в клетки. Обладает способностью удерживать в организме воду,
                                        что необходимо для предотвращения обезвоживания. Оказывает значительное влияние на белковый обмен. В состав комплекса Про Баланс ионы калия и натрия
                                        включены в таком соотношении, которое способствует поддержанию работы сердца, сосудов, мышц, нормализации кровяного давления.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Хром</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">
                                        Ни одна клетка не может без него функционировать. Хром наряду с инсулином помогает организму лучше усваивать сахар, принимает участие в транспортировке белков,
                                        нормализует углеводный обмен и работу щитовидной железы, необходим для правильного роста и регенерации тканей.
                                        Хром не позволяет холестерину накапливаться в крови и помогает нормализовать вес, благодаря участию в углеводном обмене и в переработке жира
                                        (не позволяя ему откладываться в организме). Хром укрепляет костную ткань, обеспечивая профилактику остеопороза, а также предупреждает возникновение гипертонии,
                                        снижает артериальное давление. Выводит из организма соли тяжелых металлов, токсины и радионуклиды.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section id="sostav">
            <v-container class="step-container">
                <h3 class="step-h3 mb-5">Майнд Мастер</h3>
                <v-row class="justify-center">
                    <v-col
                        cols="12"
                        md="4"
                        class="text-center"
                    >
                        <v-img class="step-sostav-img mx-auto" src="/img/converter/mindmaster.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/made-in-germany.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/fresenius.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/kolner-list.png"/>
                        <v-img class="step-certified-img" src="/img/converter/certified/patent.png"/>
                    </v-col>

                    <v-col
                        cols="12"
                        md="8"
                    >
                        <p class="step-p">
                            Майнд Мастер нейтрализует пагубное воздействие стресса на организм. Стресс нарушает работу кишечника, что существенно снижает процесс очищения организма.
                        </p>
                        <p class="step-p">
                            Имеет клинически доказанную эффективность*.
                        </p>
                        <p class="step-p">
                            Продукт запатентован**
                        </p>

                        <h4 class="step-h4 mb-3">Действие продукта на организм:</h4>
                        <ul class="step-ul">
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Действует сразу в двух направлениях: помогает снизить уровень стресса и существенно повысить работоспособность
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Повышает умственную концентрацию и внимание
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Рекомендован школьникам и студентам для улучшения памяти и мозговой деятельности
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Способствует нормализации работы кишечника
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Поддерживает обмен веществ
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Быстро восстанавливает организм после физических и психологических нагрузок
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Оказывает мощную антиоксидантную поддержку
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Способствует повышению выносливости
                            </li>
                            <li>
                                <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                Не содержит ГМО, сахар, глютен, лактозу, а также вещества животного происхождения
                            </li>
                        </ul>

                        <p class="snoska">
                            <span>*</span>
                            По данным исследования, проведенного компанией LR совместно с клиникой Шаритé при Берлинском университете, а также Европейским агентством по безопасности продуктов питания.
                        </p>
                        <p class="snoska">
                            <span>**</span>
                            Номер патента: DE 10 2013 205 049 A1
                        </p>

                        <h2 class="soctav-title-green">В составе Майнд Мастер:</h2>
                        <v-expansion-panels popout focusable>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">L-карнитин</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Является участником общего метаболизма в организме, оказывает влияние на процессы производства энергии и сжигание жиров, усиливает активность тестостерона в клетках.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Коэнзим Q10</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Способствует выработке энергии на клеточном уровне, оказывает благотворное влияние на сердечнососудистую, нервную систему и головной мозг. Способствует снижению веса. Поддерживает иммунную систему. Замедляет процесс старения человека. Проявляет ярко выраженную антиоксидантную активность. Активизирует восстановление тканей.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Ресвератрол</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Мощный антиоксидант, уменьшает концентрацию холестерина крови и тромбообразование, снижает выраженность воспалительных реакций, стимулирует образование коллагена в коже, что способствует поддержанию ее эластичности; снижает выраженность аллергических реакций, уменьшая образование гистамина; обладает иммуностимулирующим эффектом; способствует снижению уровня глюкозы в крови.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Хлорофилл</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Зеленый пигмент растений. С давних времен его использовали для лечения кожных ран и язв. Жидкий хлорофилл способствует увеличению качества и количества красных кровяных клеток (эритроцитов), поскольку его химическая формула родственна гемоглобину. Является мощным антиоксидантом и убивает микробы, тормозит рост новых микроорганизмов; борется с неприятным запахом изо рта.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Витаминный комплекс</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <ul class="step-ul-exp mt-7">
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            Витамин Е – антиоксидант, предотвращающий окисление ненасыщенных жиров.
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            Витамин В1 особенно необходим людям, переносящим тяжелые физические или умственные нагрузки, восстанавливающимся после заболеваний и т.д. Активизирует работу всех внутренних органов и восстанавливает защитные силы организма. Особое внимание витамину B1 стоит уделить людям преклонного возраста, т.к. у них заметно снижена способность к усвоению и синтезу любых витаминов. Витамин B1 рекомендуют при кожных заболеваниях нервного характера. Дополнительные дозы тиамина улучшают мозговую активность, повышают способность к усвоению информации, улучшают состояние при депрессиях.
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            Витамин В9 необходим для выработки гормонов «счастья»; влияет на эмоциональный фон, деление клеток, рост и развитие всех тканей, помогает наладить работу иммунной системы, поддерживает сердечнососудистую систему. Благотворно влияет на работу кроветворной системы, состояние печени и на пищеварительную систему в целом. Кроме этого, обеспечивает передачу импульсов между клетками нервной системы, регулирует процессы возбуждения и торможения нервной системы, сглаживает последствия стрессовых ситуаций.
                                        </li>
                                        <li>
                                            <v-icon class="step-li-icon">mdi-check-bold</v-icon>
                                            Витамин В12 способствует развитию эритроцитов и формированию нервных волокон. Оказывает значительное влияние на обмен веществ, обмен жиров и углеводов в организме.
                                        </li>
                                    </ul>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Стевиол гликозид</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">В стевии содержится много антиоксидантов, минеральных веществ, а также витамины А, С, Е, витамины группы B. Стевия способна замедлять старение клеток живого организма, укреплять иммунитет, эта медовая трава обладает антисептическими и антигрибковыми свойствами, благотворно влияет на работу сердечнососудистой, нервной, пищеварительной систем.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Ксантановая камедь</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Помогает стабилизировать различные растворы, придавать им однородность и эластичность.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Лимонная кислота и сорбат калия</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Природные консерванты.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Виноградный сок (в составе Красной формулы)</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Сок винограда считается очень ценным продуктом в лечебном, диетическом и пищевом отношении. Его ценность объясняется большим содержанием витаминов и других биологически активных веществ, принимающих участие во множестве разнообразных процессов в организме.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Экстракт зеленого чая</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Обладает тонизирующим и противовоспалительным эффектом. Один из лучших антиоксидантов, оказывает мощное противовирусное действие.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Аскорбиновая кислота</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Органическое соединение, родственное глюкозе, является одним из основных веществ в человеческом рационе, которое необходимо для нормального функционирования соединительной и костной ткани. Выполняет биологические функции восстановителя и кофермента некоторых метаболических процессов, антиоксидант.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Глюкоза</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Является важным источником энергии в организме, необходима человеку для поддержания жизнедеятельности. Обеспечивает нормальное функционирование организма при интенсивных физических, эмоциональных и интеллектуальных нагрузках, а также быстрое реагирование мозга в экстренных ситуациях.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel class="exp-panel">
                                <v-expansion-panel-header class="exp-title">Листовой гель Алоэ Вера</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-7 step-p-exp">Лечебные свойства Алоэ Вера используются в лечении гастрита, артрита, язвы желудка, болезней горла, легких, нарушений пищеварения, любых воспалительных процессов. Это кладезь витаминов и микроэлементов, а также клетчатки и ферментов, которые помогают усваивать пищу и нормализуют микрофлору кишечника. Также он эффективно справляется с воспалениями и помогает снять боль. Показан людям страдающим нарушениями сна, как один из компонентов комплексной терапии, для улучшения иммунитета и поднятия общего тонуса организма. Является проводником ценных веществ в клетки.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>

                <v-divider class="mt-10" />

                <div class="step-btn-bottom mx-auto mt-10 mb-10">
                    <v-btn
                        color="success"
                        :to="{name: 'HPlan', query: { p: this.$route.query.p }}"
                        class="btn-one"
                    >
                        Главная
                    </v-btn>

                    <v-btn
                        color="success"
                        :to="{name: 'HStepTwo', query: { p: this.$route.query.p }}"
                        class="btn-two"
                    >
                        Шаг 2
                    </v-btn>
                </div>
            </v-container>
        </section>

        <section id="consultant">
            <v-container class="text-center">
                <h2 class="display-2 font-weight-bold mt-10 mb-3 text-uppercase">Бесплатная консультация</h2>

                <p class="mb-5">Свяжитесь с вашим персональным консультантом LR Health & Beauty</p>

                <v-responsive
                    class="mx-auto mb-10"
                    width="56"
                >
                    <v-divider class="mb-1"></v-divider>

                    <v-divider></v-divider>
                </v-responsive>

                <v-card
                    class="v-card-profile v-card--material mt-16 pa-3 mx-auto"
                    max-width="480"
                >
                    <div class="d-flex grow flex-wrap">
                        <div
                            class="v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>

                            <h6
                                class="display-1 mb-3 green--text"
                                v-if="userData.who_is"
                            >
                                <v-icon class="mr-2 green--text">mdi-seal-variant</v-icon>
                                {{ userData.who_is }}
                            </h6>

                            <p v-if="userData.country || userData.city">
                                <span v-if="userData.country">{{ userData.country }}</span>
                                <span v-if="userData.country && userData.city">, </span>
                                <span v-if="userData.city">{{ userData.city }}</span>
                            </p>
                        </v-card-text>
                    </div>
                    <v-divider/>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-send</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.phone_viber"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            :href="`viber://chat?number=${userData.phone_viber}`"
                                            target="_blank"
                                            style="text-decoration: none"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="deep-purple lighten-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-viber</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Viber</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.instagram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            :href="`${userData.instagram}`"
                                            target="_blank"
                                            style="text-decoration: none"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="pink darken-1"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-instagram</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Instagram</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.fb_messenger"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            :href="`${userData.fb_messenger}`"
                                            target="_blank"
                                            style="text-decoration: none"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="light-blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-facebook-messenger</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Facebook Messenger</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.vkontakte"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            :href="`${userData.vkontakte}`"
                                            target="_blank"
                                            style="text-decoration: none"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue darken-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-vk</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться во VKontakte</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.odnoklassniki"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            :href="`${userData.odnoklassniki}`"
                                            target="_blank"
                                            style="text-decoration: none"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="orange darken-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-odnoklassniki</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Одноклассники</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-container>
        </section>

        <v-footer
            class="justify-center"
            height="100"
        >
            <div class="title font-weight-light grey--text text-center">
                &copy; {{ (new Date()).getFullYear() }} — liferecover.ru— независимый партнер LR Health & Beauty
            </div>
        </v-footer>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "HStepOne",
    data: () => ({
        loading: false,
    }),
    metaInfo: {
        title: 'Очищение - Шаг 1 | Программа 3 шага к твоему здоровью',
        meta: [
            { vmid: 'description', property: 'description', content: 'Инновационные решения для людей, которые хотят жить дольше, выглядеть моложе и чувствовать себя превосходно' },
            { vmid: 'og:title', property: 'og:title', content: 'Очищение - Шаг 1 | Программа 3 шага к твоему здоровью' },
            { vmid: 'og:description', property: 'og:description', content: 'Инновационные решения для людей, которые хотят жить дольше, выглядеть моложе и чувствовать себя превосходно' },
            { vmid: 'og:image', property: 'og:image', content: '/img/LogoLifePlus.png' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'HStepOne', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        })
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    }
}
</script>

<style lang="sass">
.step-container
    max-width: 1440px

.step-h1
    font-size: 32px
    text-transform: uppercase
    text-decoration: underline

.step-h2
    font-size: 28px
    text-transform: uppercase
    background-color: white

.step-h3
    font-size: 22px
    text-transform: uppercase
    background-color: #659c82
    color: #fff
    padding: 12px 20px
    text-align: center

.step-h4
    font-size: 18px

.exp-panel.v-expansion-panel--active
    border: 1px solid #4fff00

.exp-title
    font-size: 16px

.soctav-title-green
    font-size: 32px
    color: #659c82
    margin: 20px 0
    font-weight: normal

.step-main-img
    max-width: 600px

.step-sostav-img
    max-width: 400px

.step-certified-img
    max-width: 100px
    display: inline-block
    margin-right: 10px !important

.step-p-main
    font-size: 22px
    color: #333333
    line-height: 36px !important

.step-p
    font-size: 18px
    color: #333333
    line-height: 26px !important

.step-p-exp
    font-size: 16px
    color: #333333

.step-ul
    list-style: none

.step-ul li
    font-size: 18px
    line-height: 32px

.step-ul-exp
    list-style: none

.step-ul-exp li
    font-size: 16px
    line-height: 28px

.step-li-icon
    color: #659c82 !important
    margin-right: 5px

.snoska
    color: #666666
    font-size: 14px
    line-height: 18px !important
    margin-top: 20px

.snoska span
    font-size: 18px

#step-tilte
    background-color: white

#sostav
    background: url('/img/converter/cellbg.png') #ffffff
    background-size: cover

.step-btn-bottom
    max-width: 600px

.step-btn-bottom .btn-one
    margin-right: 20px

.step-btn-bottom .btn-two
    float: right


#consultant
    background-color: #f8f8f8

@media(max-width: 980px)
    .step-p-main
        font-size: 18px

@media(max-width: 600px)
    .step-p-main
        font-size: 16px

@media(max-width: 480px)

</style>